import _objectSpread from "D:/OOO/982/prod/iot-report-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
import { off, on } from "@/views/LargeScreen/components/datav2/tools";
import { mapState } from "vuex";
export default {
  name: 'app',
  data: function data() {
    return {
      isReload: true
    };
  },
  computed: _objectSpread({}, mapState('report/cityStore', ['city'])),
  methods: {
    reload: function reload() {
      var _this = this;

      this.isReload = false;
      this.$nextTick(function () {
        _this.isReload = true;
      });
    }
  },
  watch: {
    "city": function city(n, o) {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.reload();
      });
    }
  },
  mounted: function mounted() {
    on(window, 'resize', this.reload);
  },
  destroyed: function destroyed() {
    off(window, 'resize', this.reload);
  }
};