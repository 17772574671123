import _toConsumableArray from "D:/OOO/982/prod/iot-report-ui/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
export var forEach = function forEach(arr, fn) {
  if (!arr.length || !fn) return;
  var i = -1;
  var len = arr.length;

  while (++i < len) {
    var item = arr[i];
    fn(item, i, arr);
  }
};
/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */

export var getIntersection = function getIntersection(arr1, arr2) {
  var len = Math.min(arr1.length, arr2.length);
  var i = -1;
  var res = [];

  while (++i < len) {
    var item = arr2[i];
    if (arr1.indexOf(item) > -1) res.push(item);
  }

  return res;
};
/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */

export var getUnion = function getUnion(arr1, arr2) {
  return Array.from(new Set([].concat(_toConsumableArray(arr1), _toConsumableArray(arr2))));
};
/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */

export var hasOneOf = function hasOneOf(targetarr, arr) {
  return targetarr.some(function (_) {
    return arr.indexOf(_) > -1;
  });
};
/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */

export function oneOf(value, validList) {
  for (var i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }

  return false;
}
/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */

var isMillisecond = function isMillisecond(timeStamp) {
  var timeStr = String(timeStamp);
  return timeStr.length > 10;
};
/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */


var isEarly = function isEarly(timeStamp, currentTime) {
  return timeStamp < currentTime;
};
/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */


var getHandledValue = function getHandledValue(num) {
  return num < 10 ? '0' + num : num;
};
/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */


var getDate = function getDate(timeStamp, startType) {
  var d = new Date(timeStamp * 1000);
  var year = d.getFullYear();
  var month = getHandledValue(d.getMonth() + 1);
  var date = getHandledValue(d.getDate());
  var hours = getHandledValue(d.getHours());
  var minutes = getHandledValue(d.getMinutes());
  var second = getHandledValue(d.getSeconds());
  var resStr = '';
  if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second;else resStr = month + '-' + date + ' ' + hours + ':' + minutes;
  return resStr;
};
/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */


export var getRelativeTime = function getRelativeTime(timeStamp) {
  // 判断当前传入的时间戳是秒格式还是毫秒
  var IS_MILLISECOND = isMillisecond(timeStamp); // 如果是毫秒格式则转为秒格式

  if (IS_MILLISECOND) Math.floor(timeStamp /= 1000); // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型

  timeStamp = Number(timeStamp); // 获取当前时间时间戳

  var currentTime = Math.floor(Date.parse(new Date()) / 1000); // 判断传入时间戳是否早于当前时间戳

  var IS_EARLY = isEarly(timeStamp, currentTime); // 获取两个时间戳差值

  var diff = currentTime - timeStamp; // 如果IS_EARLY为false则差值取反

  if (!IS_EARLY) diff = -diff;
  var resStr = '';
  var dirStr = IS_EARLY ? '前' : '后'; // 少于等于59秒

  if (diff <= 59) resStr = diff + '秒' + dirStr; // 多于59秒，少于等于59分钟59秒
  else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr; // 多于59分钟59秒，少于等于23小时59分钟59秒
  else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr; // 多于23小时59分钟59秒，少于等于29天59分钟59秒
  else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr; // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
  else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp);else resStr = getDate(timeStamp, 'year');
  return resStr;
};
/**
 * @returns {String} 当前浏览器名称
 */

export var getExplorer = function getExplorer() {
  var ua = window.navigator.userAgent;

  var isExplorer = function isExplorer(exp) {
    return ua.indexOf(exp) > -1;
  };

  if (isExplorer('MSIE')) return 'IE';else if (isExplorer('Firefox')) return 'Firefox';else if (isExplorer('Chrome')) return 'Chrome';else if (isExplorer('Opera')) return 'Opera';else if (isExplorer('Safari')) return 'Safari';
};
/**
 * @description 绑定事件 on(element, event, handler)
 */

export var on = function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
}();
/**
 * @description 解绑事件 off(element, event, handler)
 */

export var off = function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
}();
/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */

export var hasKey = function hasKey(obj, key) {
  if (key) return key in obj;else {
    var keysArr = Object.keys(obj);
    return keysArr.length;
  }
};
/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */

export var objEqual = function objEqual(obj1, obj2) {
  var keysArr1 = Object.keys(obj1);
  var keysArr2 = Object.keys(obj2);
  if (keysArr1.length !== keysArr2.length) return false;else if (keysArr1.length === 0 && keysArr2.length === 0) return true;
  /* eslint-disable-next-line */
  else return !keysArr1.some(function (key) {
    return obj1[key] != obj2[key];
  });
};
/**
 * echarts tooltip轮播
 * @param chart ECharts实例
 * @param chartOption echarts的配置信息
 * @param options object 选项
 * {
 *  interval    轮播时间间隔，单位毫秒，默认为3000
 *              true表示循环所有series的tooltip，false则显示指定seriesIndex的tooltip
 *  seriesIndex 默认为0，指定某个系列（option中的series索引）循环显示tooltip，
 *              当loopSeries为true时，从seriesIndex系列开始执行。
 * }
 * @returns {{clearLoop: clearLoop}|undefined}
 */

export function autoHover(myChart, option, num, time) {
  var defaultData = {
    // 设置默认值
    time: 3000,
    num: 14
  };

  if (!time) {
    time = defaultData.time;
  }

  if (!num) {
    num = defaultData.num;
  }

  var count = 0;
  var timeTicket = 0; // 清除定时器

  function clearLoop() {
    if (timeTicket) {
      clearInterval(timeTicket);
      timeTicket = 0;
    }

    myChart.off('mousemove', stopAutoShow);
    myChart.getZr().off('mousemove', zRenderMouseMove); // myChart.getZr().off('globalout', zRenderGlobalOut);
  } // 关闭轮播


  function stopAutoShow() {
    if (timeTicket) {
      clearInterval(timeTicket);
      timeTicket = 0;
    }
  }

  function zRenderMouseMove(param) {
    if (param.event) {// 阻止canvas上的鼠标移动事件冒泡
      // param.event.cancelBubble = true;
    }

    stopAutoShow();
  }

  timeTicket && clearInterval(timeTicket);
  timeTicket = setInterval(function () {
    myChart.dispatchAction({
      type: 'downplay',
      seriesIndex: 0 // serieIndex的索引值   可以触发多个

    });
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: count
    });
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: count
    });
    count++;

    if (count >= num) {
      count = 0;
    }
  }, time);
  myChart.on('mouseover', function (params) {
    clearInterval(timeTicket);
    myChart.dispatchAction({
      type: 'downplay',
      seriesIndex: 0
    });
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: params.dataIndex
    });
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: params.dataIndex
    });
  });
  myChart.on('mouseout', function () {
    timeTicket && clearInterval(timeTicket);
    timeTicket = setInterval(function () {
      myChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0 // serieIndex的索引值   可以触发多个

      });
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: count
      });
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: count
      });
      count++;

      if (count >= 14) {
        count = 0;
      }
    }, 3000);
  });
  return {
    clearLoop: clearLoop
  };
}