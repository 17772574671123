// 定义请求地址映射关系
const glbBaseUrlMap = {
  // 电池设备请求后端baseURL
  iotApp: identifyEnvironment('VUE_APP_BASEURL_1'),
  admin: identifyEnvironment('VUE_APP_BASEURL_2'),
  iot: identifyEnvironment('VUE_APP_BASEURL_4'),
  'rent/admin': identifyEnvironment('VUE_APP_BASEURL_2'),
  // allUserBind 绑定电池的前缀
  'net/v1/': identifyEnvironment('VUE_APP_BASEURL_2'),
  // allUserBind 绑定电池的前缀(网点服务）
  'iotReport/report': identifyEnvironment('VUE_APP_BASEURL_REPORT'),
  // 数据大屏需要的后端地址
  'iotReport/reportV2': identifyEnvironment('VUE_APP_BASEURL_REPORT'),
  // 数据大屏需要的后端地址
  ljjunAdmin: identifyEnvironment('VUE_APP_BASEURL_2')
};

// 增加环境识别处理，根据请求的URL，获取正确的后端请求地址
export function identifyEnvironment(key) {
  // 判断当前访问的是否为正式服务器地址，如果不是则使用test作为后缀
  let url = '';
  // 如果是开发环境，应该直接使用测试环境中的配置
  if (process.env.NODE_ENV !== 'production') {
    if (process.env.hasOwnProperty(key)) {
      url = process.env[key];
      // 在开发模式下 URL可以填写端口，实际请求的是请求地址加端口，
      // 注：如果前后端在不同机器上，需要将地址写全，反之只需要写端口即可
      // 检查是否包含http
      if (url.indexOf('http') === -1) {
        url = location.protocol + '//' + location.hostname + ':' + url;
      }
    }
    return url;
  }
  if (process.env.hasOwnProperty(key)) {
    url = process.env[key];
  }
  return url;
}
function processStr(str) {
  if (str) {
    if (!str.startsWith('/')) {
      str = '/' + str;
    }
    if (!str.endsWith('/')) {
      str = str + '/';
    }
    str = str.replace(/\/\//g, '/');
  }
  return str;
}

// 根据请求地址如获取对应的微服务请求的前置url
// 例如: /iotApp/list,取iotApp将自动获得环境中的地址
export function base_url_function(config) {
  if (config.url && config.hasOwnProperty('baseURL')) {
    const keys = Object.keys(glbBaseUrlMap).filter(s => processStr(config.url).startsWith(processStr(s)));
    if (keys.length === 1) {
      config.baseURL = glbBaseUrlMap[keys[0]];
      const excludeStrs = ['/ljjunAdmin/']; // 定义标识，该标识将用于标识请求的server信息
      excludeStrs.forEach(r => {
        config.url = config.url.replace(r, '/');
      });
    }
  }
  return config;
}