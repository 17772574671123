import { off, on } from "@/views/LargeScreen/components/datav2/tools";
import { mapState } from "vuex";
export default {
  name: 'app',
  data() {
    return {
      isReload: true
    };
  },
  computed: {
    ...mapState('report/cityStore', ['city'])
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    }
  },
  watch: {
    "city": function (n, o) {
      this.$nextTick(() => {
        this.reload();
      });
    }
  },
  mounted() {
    on(window, 'resize', this.reload);
  },
  destroyed() {
    off(window, 'resize', this.reload);
  }
};