import dayjs from 'dayjs';
import { mapActions, mapState } from "vuex";
export default {
  name: 'TopHeader',
  model: {
    prop: 'cityVal',
    event: 'change'
  },
  props: {
    cityVal: {
      type: String,
      default: '全国'
    }
  },
  data() {
    return {
      time: dayjs().format('HH:mm:ss')
    };
  },
  computed: {
    ...mapState('report/cityStore', ['city']),
    week() {
      let now = new Date();
      let day = now.getDay();
      let weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      return weeks[day];
    }
  },
  methods: {
    ...mapActions('report/cityStore', ['toggleCity']),
    changeCity(name) {
      this.$root.eventHub.$emit('clearInterval', name);
      this.toggleCity({
        'rst': name
      });
      this.$emit("change", name);
    },
    refreshTime() {
      this.time = dayjs().format('YYYY-MM-DD HH:mm:ss');
    }
  },
  mounted() {
    if (this.city) {
      this.$emit("change", this.city);
    }
    this.toggleCity({
      'rst': this.city ? this.city : this.cityVal
    }); //设置默认的地区
    this.timeInterval = setInterval(() => {
      this.refreshTime();
    }, 1000);
  }
};