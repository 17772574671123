import Vue from 'vue';
import VueRouter from 'vue-router';
import largeDataScreenV3 from "@/views/LargeScreen/LargeDataScreenV3";
import largeDataScreenV4 from "@/views/LargeScreen/LargeDataScreenV4";
Vue.use(VueRouter);
const routes = [{
  path: '/',
  name: 'home',
  component: largeDataScreenV4
}, {
  path: '/about',
  name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */'../views/AboutView.vue')
}];
const router = new VueRouter({
  routes
});
export default router;