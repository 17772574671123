var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "data-view"
    }
  }, [_c("dv-full-screen-container", [_c("div", {
    staticStyle: {
      height: "100vh"
    }
  }, [_c("top-header", {
    model: {
      value: _vm.city,
      callback: function ($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  }), _c("dv-decoration-3", {
    staticStyle: {
      height: "30rem"
    }
  }), _c("div", {
    attrs: {
      flex: "main:between"
    }
  }, [_c("dv-border-box-" + _vm.themeFrame, {
    tag: "component",
    staticStyle: {
      width: "99.5vw",
      "max-width": "100vw",
      height: "calc(100vh - 70rem)",
      margin: "0 auto"
    }
  }, [_c("div", {
    staticStyle: {
      margin: "5rem",
      height: "calc(100vh - 70rem)",
      position: "relative"
    }
  }, [_c("Row", {
    staticStyle: {
      height: "calc(100vh - 80rem)"
    }
  }, [_c(_vm.componentName, {
    ref: "report",
    tag: "component",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      theme: _vm.theme,
      city: _vm.city,
      isReq: _vm.isReq,
      "is-socket": _vm.isSocket,
      isUseTestData: _vm.isUseTestData
    }
  })], 1)], 1)])], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };