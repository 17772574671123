import datav from './components/data4Mobile/index.vue';
export default {
  name: "LargeDataScreenV4",
  components: {
    datav
  },
  data() {
    return {};
  },
  computed: {
    isMobile() {
      return this.$utils.isMobile();
    }
  },
  methods: {
    toggleFullScreen() {
      // if(!this.isMobile) return;
      try {
        let fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || undefined;
        if (fullscreenEnabled) {
          let de = document.documentElement;
          //打开全屏
          if (de.requestFullscreen) {
            de.requestFullscreen().then(status => {
              // 正常打开全屏不需要任何操作
              console.log('正常打开全屏');
              // do something
            }, err => {
              console.log('不正常打开全屏');
              // do something
            });
          } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
          } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
          }
        } else {
          console.log('无法打开全屏');
        }
      } catch (e) {
        console.log('无法打开全屏');
      }
    },
    // 判断全屏
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull = document.mozFullScreen || document.fullScreen ||
      //谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    }
  },
  mounted() {
    // 如果是电脑浏览器则自动进行全屏操作
    if (!this.isMobile || localStorage.getItem('trigger')) {
      localStorage.removeItem('trigger');
      this.$nextTick(() => {
        setTimeout(() => {
          this.toggleFullScreen();
        }, 1000);
      });
    } else {
      //如果切换浏览器为横屏模式
      window.addEventListener("orientationchange", () => {
        if (window.orientation === 90) {
          // 重新刷新页面让整个页面进行自适应，同时支持在打开一瞬间自动全屏操作
          localStorage.setItem('trigger', true);
          setTimeout(() => {
            location.reload();
          }, 100);
          // this.$Modal.warning({
          //   title: "提示",
          //   content: '横屏后，将自动刷新浏览器，请稍后点击全屏按钮',
          //   onOk(){
          //
          //     location.reload();
          //   }
          // })
        }

        if (window.orientation === 0) {}
      }, false);
    }
  }
};