var getFontSize = function getFontSize(res) {
  var clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return;
  var fontSize = clientWidth / 1280;
  return res * fontSize;
};

var isMobile = function isMobile() {
  var userAgentInfo = navigator.userAgent;
  var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var mobile_flag = false; //����userAgent�ж��Ƿ����ֻ�

  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }

  var screen_width = window.screen.width;
  var screen_height = window.screen.height; //������Ļ�ֱ����ж��Ƿ����ֻ�

  if (screen_width > 325 && screen_height < 750) {
    mobile_flag = true;
  }

  return mobile_flag;
};

export default {
  getFontSize: getFontSize,
  isMobile: isMobile
};