var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "data-view" } },
    [
      _c("dv-full-screen-container", [
        _c(
          "div",
          { staticStyle: { height: "100vh" } },
          [
            _c("top-header", {
              model: {
                value: _vm.city,
                callback: function ($$v) {
                  _vm.city = $$v
                },
                expression: "city",
              },
            }),
            _c("dv-decoration-3", { staticStyle: { height: "1rem" } }),
            _c(
              "div",
              { attrs: { flex: "main:between" } },
              [
                _c(
                  "dv-border-box-" + _vm.themeFrame,
                  {
                    tag: "component",
                    staticStyle: {
                      width: "99vw",
                      "max-width": "100vw",
                      height: "calc(100vh - 11rem)",
                      margin: "auto",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "1rem",
                          height: "100%",
                          position: "relative",
                        },
                      },
                      [
                        _c(
                          "Row",
                          { staticStyle: { height: "calc(100vh - 13rem)" } },
                          [
                            _c(_vm.componentName, {
                              ref: "report",
                              tag: "component",
                              staticStyle: { width: "100%" },
                              attrs: {
                                theme: _vm.theme,
                                city: _vm.city,
                                isReq: _vm.isReq,
                                "is-socket": _vm.isSocket,
                                isUseTestData: _vm.isUseTestData,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }