import _objectSpread from "D:/OOO/982/prod/iot-report-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "D:/OOO/982/prod/iot-report-ui/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.json.stringify.js";
import axios from '@/libs/api.request'; // 公共查询方法
// this.$set(data,“url”,true) 为必填
// 如果control中为RequestBody接收参数，则需要使用this.$set(data,isReqBody,true)为data中设置isReqBody

export default function (data) {
  if (_typeof(data) !== 'object') {
    console.error('传入的参数不是对象，请检查...');
    return new Promise(function (resolve, reject) {
      reject(new Error('传入的参数不是对象，请检查...'));
    });
  } else {
    if (!data.url) {
      console.error('无法获取URL参数值，请检查');
      return new Promise(function (resolve, reject) {
        reject(new Error('无法获取URL参数值，请检查'));
      });
    }

    var cUrl = '';

    if (data.url) {
      cUrl = data.url;
      delete data.url;
    } // 默认为JSON格式


    var responseType = 'json';

    if (data._resType !== undefined) {
      responseType = data._resType; // 采用传入的自定义格式

      delete data._resType;
    } // 默认请求类型为POST，后端control的类型也应该为POST


    var methodType = 'POST';

    if (data.id) {
      methodType = 'PUT';
    }

    if (data.methodType) {
      methodType = data.methodType; // 如果用户自定义，则采用自定义请求类型

      delete data.methodType;
    } // 如果后端control采用的是RequestBody形式，则自定义对象中isReqBody需指明设置为true


    var isReqBody = true;

    if (data.isReqBody !== undefined) {
      isReqBody = data.isReqBody;
      delete data.isReqBody;
    }

    var param = new URLSearchParams(); // formData提交数据，isFormData=true,data.formData为FormData类型

    /**
     * 实例
     * data:{
     *   isFormData:true,
     *   url:'XXXXXXX',
     *   formData:FormData对象类型
     * }
     */

    var headers = {};

    if ((data === null || data === void 0 ? void 0 : data.isFormData) === true) {
      headers = {
        'Content-Type': 'multipart/form-data',
        token: 'none',
        language: localStorage.getItem('localeLanguage') || 'zh-cn',
        'Content-Security-Policy': "script-src 'self'; object-src 'none';style-src 'self'; child-src https:"
      };
      param = data.formData;
      isReqBody = true; // 为减少配置以及兼容后端历史代码，此处将默认设置为true。
    } else if (data !== null && data !== void 0 && data.data) {
      // 后台参数接收方式： funHandle(@RequestBody String argStr)
      param = data === null || data === void 0 ? void 0 : data.data;

      if (param && typeof param !== 'string') {
        param = JSON.stringify(param);
      }
    } else {
      var obj = {};

      for (var i in data) {
        if (isReqBody) {
          obj[i] = data[i];
        } else {
          if (i === 'url') continue;
          param.append(i + '', data[i]);
        }
      }

      if (isReqBody) {
        param = obj;
      }
    } // get: params; other: data
    // let paramData = methodType !== 'GET' && methodType !== 'get' ? {data: param} : {params: param};


    var paramData = {
      data: param,
      params: param
    };

    var req = _objectSpread(_objectSpread({
      url: encodeURI(cUrl, 'utf-8')
    }, paramData), {}, {
      method: methodType,
      responseType: responseType
    }, headers);

    return axios.request(req).catch(function (error) {
      return Promise.reject(error);
    });
  }
}