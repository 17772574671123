import "core-js/modules/es.array.push.js";
// import { Message, MessageBox } from 'element-ui'
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { base_url_function } from '@/config/BaseUrlMapping';
class HttpRequest {
  constructor(baseUrl = baseURL) {
    this.baseUrl = baseUrl;
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        token: 'Bearer ',
        Authorization: 'Bearer ',
        language: localStorage.getItem('localeLanguage') || 'zh-cn',
        'Content-Security-Policy': "script-src 'self'; object-src 'none';style-src 'self'; child-src https:"
      }
    };
    return config;
  }
  errorCode(status, error = undefined) {
    let message = '';
    switch (status) {
      case 110:
        message = '代码报错';
        break;
      case 400:
        message = '请求错误';
        break;
      case 401:
        message = '未授权，请登录';
        break;
      case 403:
        message = '拒绝访问';
        break;
      case 404:
        message = error ? `请求地址出错: ${error.response.config.url}` : '';
        break;
      case 408:
        message = '请求超时';
        break;
      case 500:
        message = '服务器内部错误';
        break;
      case 501:
        message = '服务未实现';
        break;
      case 502:
        message = '网关错误';
        break;
      case 503:
        message = '服务不可用';
        break;
      case 504:
        message = '网关超时';
        break;
      case 505:
        message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
    return message;
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      return base_url_function(config);
    }, error => {
      // 发送失败
      console.log(error);
      return Promise.reject(error);
    });
    // 响应拦截
    instance.interceptors.response.use(response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data;
      // 判断为二进制数据流时
      if (response.config.responseType == 'blob' && response.status == 200 && dataAxios.code != 500) {
        return response;
      }
      // 这个状态码是和后端约定的
      const {
        code
      } = dataAxios;
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
        return dataAxios;
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 0:
          case 1:
          case 200:
          case 1000:
            // [ 示例 ] code === 0  ||  code === 200 代表没有错误, 1000为业务错误，弹框提示非系统错误
            return dataAxios;
          case 400:
            // 业务判断错误需要前台提示,与熊一约定需要弹窗出来的系统异常，500不需要弹出出来的异常
            errorCreate(`${i18n.t(dataAxios.msg)}`);
            break;
          case 500:
            // [ 示例 ] 其它和后台约定的 code
            // errorCreateNoMessageShow(`[ code: 500 ] 程序代码存在问题（不弹框），请检查后台控制台/日志: ${response.config.url}`)
            errorCreate(`${i18n.t(dataAxios.msg)}`);
            break;
          case 401:
            setTimeout(() => {
              errorCreate('用户已超时，请重新登陆.');
            }, 1500);
            // [ 示例 ] 其它和后台约定的 code
            store.dispatch('d2admin/account/logout', {
              confirm: false
            });
            router.push({
              name: 'login'
            });
            break;
          default:
            // 不是正确的 code
            errorCreateNoMessageShow(`[ code: 110 ] 程序代码存在问题，请检查后台控制台/日志: ${response.config.url}`);
            break;
        }
      }
    }, error => {
      var _error$response;
      const status = get(error, 'response.status');
      error.message = this.errorCode(status, error);
      if (status == '404') {
        errorCreate(error.message);
      }
      if (status == '401' || status == 401) {
        setTimeout(() => {
          errorCreate('用户已超时，请重新登陆.');
        }, 1500);
        // [ 示例 ] 其它和后台约定的 code
        store.dispatch('d2admin/account/logout', {
          confirm: false
        });
        router.push({
          name: 'login'
        });
      }
      if (status == '500') {
        errorCreate(error.message);
      }
      // errorLog(error)
      else if (error !== null && error !== void 0 && (_error$response = error.response) !== null && _error$response !== void 0 && _error$response.data) {
        var _error$response2, _error$response2$data;
        let errorMsg = '';
        if (error !== null && error !== void 0 && (_error$response2 = error.response) !== null && _error$response2 !== void 0 && (_error$response2$data = _error$response2.data) !== null && _error$response2$data !== void 0 && _error$response2$data.error) {
          errorMsg = error.response.data.error;
        } else {
          var _error$response3;
          errorMsg = (error === null || error === void 0 ? void 0 : (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.data) || '';
        }
        errorCreate(errorMsg);
      }
      return Promise.reject(error);
    });
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;