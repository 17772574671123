import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import largeDataScreenV3 from "@/views/LargeScreen/LargeDataScreenV3";
import largeDataScreenV4 from "@/views/LargeScreen/LargeDataScreenV4";
Vue.use(VueRouter);
var routes = [{
  path: '/',
  name: 'home',
  component: largeDataScreenV4
}, {
  path: '/about',
  name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import(
    /* webpackChunkName: "about" */
    '../views/AboutView.vue');
  }
}];
var router = new VueRouter({
  routes: routes
});
export default router;