let getFontSize = res => {
  const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return;
  let fontSize = clientWidth / 1280;
  return res * fontSize;
};
let isMobile = () => {
  let userAgentInfo = navigator.userAgent;
  let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  let mobile_flag = false;
  //����userAgent�ж��Ƿ����ֻ�
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }
  var screen_width = window.screen.width;
  var screen_height = window.screen.height;
  //������Ļ�ֱ����ж��Ƿ����ֻ�
  if (screen_width > 325 && screen_height < 750) {
    mobile_flag = true;
  }
  return mobile_flag;
};
export default {
  getFontSize,
  isMobile
};