var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "top-header"
    }
  }, [_c("dv-decoration-8", {
    staticClass: "header-left-decoration",
    staticStyle: {
      height: "45rem"
    }
  }), _c("dv-decoration-5", {
    staticClass: "header-center-decoration",
    staticStyle: {
      height: "45rem"
    }
  }), _c("dv-decoration-8", {
    staticClass: "header-right-decoration",
    staticStyle: {
      height: "45rem"
    },
    attrs: {
      reverse: true
    }
  }), _c("div", {
    staticClass: "center-title"
  }, [_vm._v("982智能换电数据大屏")]), _c("div", {
    staticClass: "time time_left"
  }, [_vm._v(_vm._s(_vm.time) + "  " + _vm._s(_vm.week))]), _c("div", {
    staticClass: "time time_right"
  }, [_c("Dropdown", {
    staticStyle: {
      "margin-left": "20px"
    },
    on: {
      "on-click": _vm.changeCity
    }
  }, [_c("a", {
    staticStyle: {
      color: "#A6E6FC"
    },
    attrs: {
      href: "javascript:void(0)"
    }
  }, [_vm._v(" " + _vm._s(_vm.cityVal) + " "), _c("Icon", {
    attrs: {
      type: "ios-arrow-down"
    }
  })], 1), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_c("DropdownItem", {
    attrs: {
      name: "全国"
    }
  }, [_vm._v("全国")]), _c("DropdownItem", {
    attrs: {
      name: "上海市"
    }
  }, [_vm._v("上海市")]), _c("DropdownItem", {
    attrs: {
      name: "西安市"
    }
  }, [_vm._v("西安市")]), _c("DropdownItem", {
    attrs: {
      name: "成都市"
    }
  }, [_vm._v("成都市")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };