export default {
  namespaced: true,
  state: {
    //大屏数据-城市选择
    city: ""
  },
  actions: {
    /**
     * @description 切换城市
     * @param {Object} context
     */
    toggleCity: function toggleCity(_ref, _ref2) {
      var commit = _ref.commit;
      var rst = _ref2.rst;
      commit('setCity', rst);
    }
  },
  mutations: {
    /**
     * @description 设置 store 里大屏数据的城市
     * @param {Object} state state
     * @param {Boolean} city city 城市
     */
    setCity: function setCity(state, city) {
      state.city = city;
    }
  }
};