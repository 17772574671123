import "D:\\OOO\\982\\prod\\iot-report-ui\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\OOO\\982\\prod\\iot-report-ui\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\OOO\\982\\prod\\iot-report-ui\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\OOO\\982\\prod\\iot-report-ui\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import restful from '@/api/cmm';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import utils from "@/libs/utils"; // flex 布局库

import 'flex.css';
import VueSocketIO from 'vue-socket.io';
import 'victormono';
Vue.config.productionTip = false;
Vue.prototype.$restful = restful;
Vue.prototype.$utils = utils;
Vue.use(ViewUI);
var connection = process.env.VUE_APP_BASEURL_REPORT;
Vue.use(new VueSocketIO({
  debug: process.env.VUE_APP_ENV === 'development',
  connection: connection
}));
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  //add by ljjun,监听全局事件
  data: {
    eventHub: new Vue()
  }
}).$mount('#app');