import _objectSpread from "D:/OOO/982/prod/iot-report-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
import { mapActions, mapState } from "vuex";
export default {
  name: 'TopHeader',
  model: {
    prop: 'cityVal',
    event: 'change'
  },
  props: {
    cityVal: {
      type: String,
      default: '全国'
    }
  },
  data: function data() {
    return {
      time: dayjs().format('HH:mm:ss')
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('report/cityStore', ['city'])), {}, {
    week: function week() {
      var now = new Date();
      var day = now.getDay();
      var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      return weeks[day];
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('report/cityStore', ['toggleCity'])), {}, {
    changeCity: function changeCity(name) {
      this.$root.eventHub.$emit('clearInterval', name);
      this.toggleCity({
        'rst': name
      });
      this.$emit("change", name);
    },
    refreshTime: function refreshTime() {
      this.time = dayjs().format('YYYY-MM-DD HH:mm:ss');
    }
  }),
  mounted: function mounted() {
    var _this = this;

    if (this.city) {
      this.$emit("change", this.city);
    }

    this.toggleCity({
      'rst': this.city ? this.city : this.cityVal
    }); //设置默认的地区

    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
  }
};