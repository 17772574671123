// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#data-view {\n  width: 100%;\n  height: 100%;\n  background-color: #030409;\n  color: #fff;\n}\n#data-view #dv-full-screen-container {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  box-shadow: 0 0 0.3rem blue;\n  display: flex;\n  flex-direction: column;\n}\n.btn {\n  border: 1rem solid red;\n  width: 1rem;\n  height: 1rem;\n  float: left;\n  left: 1rem;\n  bottom: 2rem;\n  position: absolute;\n  z-index: 999;\n  background-color: yellow;\n}", ""]);
// Exports
module.exports = exports;
