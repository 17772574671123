//
//
//
//
//
//
import datav from './components/datav3/index.vue';
export default {
  name: "LargeDataScreenV3",
  components: {
    datav: datav
  },
  data: function data() {
    return {};
  },
  methods: {
    toggleFullScreen: function toggleFullScreen() {
      try {
        var fullscreenEnabled = document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled || undefined;

        if (fullscreenEnabled) {
          var de = document.documentElement; //打开全屏

          if (de.requestFullscreen) {
            de.requestFullscreen().then(function (status) {
              // 正常打开全屏不需要任何操作
              console.log('正常打开全屏'); // do something
            }, function (err) {
              console.log('不正常打开全屏'); // do something
            });
          } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
          } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
          }
        } else {
          console.log('无法打开全屏');
        }
      } catch (e) {
        console.log('无法打开全屏');
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      setTimeout(function () {
        _this.toggleFullScreen();
      }, 100);
    });
  }
};