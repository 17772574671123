import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import topHeader from './topHeader';
import dataV from '@jiaminghi/data-view';
import Vue from 'vue';
Vue.use(dataV);
export default {
  name: 'DataView',
  components: {
    topHeader: topHeader,
    cityReport: function cityReport() {
      return import("@/views/LargeScreen/components/data4Mobile/cityReport");
    },
    allReport: function allReport() {
      return import("@/views/LargeScreen/components/data4Mobile/allReport");
    }
  },
  data: function data() {
    return {
      timer: 0,
      //秒
      theme: "12",
      //默认主题
      themeFrame: "8",
      city: '全国',
      // 选中的城市名称
      isReq: true,
      // 是否启用请求后台服务,可用于调测界面布局
      isSocket: true,
      //是否使用socket，如果false，则使用axios，否则使用socket
      isUseTestData: false // 是否使用测试数据

    };
  },
  watch: {
    city: function city(n, o) {
      var _this$$refs$report;

      (_this$$refs$report = this.$refs.report) === null || _this$$refs$report === void 0 ? void 0 : _this$$refs$report.init(n);
    }
  },
  computed: {
    // 定义组件名称
    componentName: function componentName() {
      return this.city === '全国' ? 'allReport' : 'cityReport';
    }
  },
  methods: {
    IsNum: function IsNum(s) {
      if (s != null) {
        var r, re;
        re = /\d*/i; //\d表示数字,*表示匹配多个数字

        r = s.match(re);
        return r == s ? true : false;
      }

      return false;
    },
    changeTheme: function changeTheme() {
      var _this$$route$query, _this$$route$query2;

      var a = ((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.a) || "8";

      if (this.IsNum(a) && a > 0 && a < 14) {
        this.themeFrame = a;
      }

      var theme = ((_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.b) || "12";

      if (this.IsNum(theme) && theme > 0 && theme < 14) {
        this.theme = theme;
      }
    },
    init: function init() {}
  },
  mounted: function mounted() {
    var _this$$refs$report2;

    this.changeTheme();
    (_this$$refs$report2 = this.$refs.report) === null || _this$$refs$report2 === void 0 ? void 0 : _this$$refs$report2.init(this.city);
  }
};